import {
  modelProps,
  loadingProps,
  loadingProps_m,
} from '../components/Gallery and Header/model';
import { LoadingPropsI, LoadingPropsI_m, WidgetPropsI } from '../types';

export const INITIAL_PROPS: WidgetPropsI = Object.entries(modelProps).reduce(
  (acc, cur) => {
    const [prop, { defaultValue }] = cur;
    return { ...acc, [prop]: defaultValue };
  },
  {} as WidgetPropsI,
);

export const getInitialLoadingProps = (
  isLoadMorePreset: boolean,
  isMobile: boolean,
): LoadingPropsI | LoadingPropsI_m => {
  const currentProps = isMobile ? loadingProps_m : loadingProps;
  const props = Object.entries(currentProps).reduce((acc, cur) => {
    const [propName, { defaultValue, loadingValue }] = cur;
    return {
      ...acc,
      [propName]: isLoadMorePreset ? loadingValue : defaultValue,
    };
  }, {} as LoadingPropsI | LoadingPropsI_m);
  return props;
};
