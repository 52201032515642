export enum PanelNames {
  ConnectAccount = 'Connect account',
  DisconnectAccount = 'Disconnect account',
  Layout = 'Layout',
  PgCustomSettings = 'Custom Pro gallery settings ',
  ExpandSettings = 'Settings',
}

export enum PgCustomOnclick {
  OpenExpand,
  NothingHappens,
  OpenLink,
}

export enum CustomSettingsPanel {
  dropdown = '#panelDropdown1',
}

export enum LayoutPanelSections {
  chooseLayout = '#panelThumbnails1',
  alignment = '#panelThumbnails2',
  imagePosition = '#panelThumbnails3',
  textTopBottomPadding = '#panelSlider1',
  textSidePadding = '#panelSlider2',
  textLineSpacing = '#panelSlider5',
  feedTopBottomPadding = '#panelSlider3',
  feedSidePadding = '#panelSlider4',
  textDivider = '#panelSectionDivider1',
  feedDivider = '#panelSectionDivider2',
  toggleImagesToShow = '#panelToggleSwitch2',
  imagesToShowNum = '#panelSlider7',
  toggleLoadMoreBtn = '#panelToggleSwitch1',
  imagesPerLoadNum = '#panelSlider6',
  customizeInLayoutPanel = '#panelRichText1',
}

export enum LayoutPanelVarNames {
  headerTopBottomPadding = 'HeaderTopBottomPadding',
  headerSidePadding = 'HeaderSidePadding',
  feedTopBottomPadding = 'FeedTopBottomPadding',
  feedSidePadding = 'FeedSidePadding',
  SideBySideContRTLstart = 'SideBySideContRTLstart',
  SideBySideContRTLend = 'SideBySideContRTLend',
  SideBySideImgRTLstart = 'SideBySideImgRTLstart',
  SideBySideImgRTLend = 'SideBySideImgRTLend',

  HeaderSpaceBetween = 'SpaceBetween',
  HeaderAlignLeft = 'OneRowAlignLeft',
  HeaderAlignRight = 'OneRowAlignRight',

  TwoRowRS = 'TwoRowRS',
  TwoRowRE = 'TwoRowRE',
  TitleCS = 'TitleCS',
  TitleCE = 'TitleCE',
  ButtonsCS = 'ButtonCS',
  ButtonsCE = 'ButtonCE',
  TitleAlignment = 'TitleAlignment',
  ButtonsAlignment = 'ButtonAlignment',
}

export enum LayoutOptions {
  Single = 'Single',
  Multi = 'Multi',
  ImagePosRight = 'Right',
  ImagePosLeft = 'Left',
  AlignRight = 'Right',
  AlignLeft = 'Left',
  AlignCenter = 'Center',
  AlignStart = 'start',
  AlignEnd = 'end',
  AlignToCenter = 'center',
}

export enum LayoutUnits {
  px = 'px',
  fr = 'fr',
}
