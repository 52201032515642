import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { ElementRoles, HeaderStates } from '../../enums';
import { preventHide } from '../../utils/Editor';
import { removeGfppFromTextElements, removeLinksFromButtonPanel } from '../../utils/Editor/gfppUtils';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI
) => {
  const { t } = flowAPI.translations;
  widgetBuilder.set({
    displayName: 'Widget Header',
    nickname: 'HeaderWidget',
  });

  widgetBuilder
    .gfpp()
    // .set('mainAction1', { behavior: 'HIDE' })
    .set('add', { behavior: 'HIDE' })
    .set('design', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' });

  // preventHide(widgetBuilder, false);
  // widgetBuilder.configureState(HeaderStates.State2, (stateBuilder) => {
  //   preventHide(stateBuilder);
  // });

  removeGfppFromTextElements(widgetBuilder, [ElementRoles.HeaderTitle])

  removeLinksFromButtonPanel(
    widgetBuilder,
    editorSDK,
    t,
    [ElementRoles.HeaderUserNameBtn],
    true,
    false
  );
};
