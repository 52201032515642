import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { EditorSDKUtils } from './editorSdkUtils';
import { mainWidgetOptions } from '../../constants';
import { PresetHeights } from '../../enums';

export const installWidget = async (editorSDK: FlowEditorSDK) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  await editorSDKUtils.reloadManifest();
  const compRef = await editorSDKUtils.addWidget(mainWidgetOptions);
  await editorSDKUtils.setWidgetHeight(compRef, PresetHeights.OneRow);
};
