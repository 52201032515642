export class ApiClient {
  httpClient;

  constructor(httpClient: any) {
    this.httpClient = httpClient;
  }

  requestOptions(instance: string, options?: object) {
    return {
      headers: {
        Authorization: instance,
        'Content-Type': 'application/json',
        ...options,
      },
    };
  }

  async get<T>(url: string, instance: string): Promise<T> {
    const res = await this.httpClient.get(url, this.requestOptions(instance));
    return res.data;
  }

  async del<T>(url: string, instance: string): Promise<T> {
    const res = await this.httpClient.delete(
      url,
      this.requestOptions(instance),
    );
    return res.data;
  }

  async post<T>(
    url: string,
    instance: string,
    data?: any,
    options?: object,
  ): Promise<T> {
    const res = await this.httpClient.post(
      url,
      data || {},
      this.requestOptions(instance, options),
    );

    return res.data;
  }

  async put<T>(url: string, instance: string, data: any): Promise<T> {
    const res = await this.httpClient.put(
      url,
      data || {},
      this.requestOptions(instance),
    );
    return res.data;
  }

  // req: HttpRequestOrHttpRequestFactory<T>, overrides?: RequestOverrides
  async request<T>(req: any): Promise<T> {
    const res = await this.httpClient.request(req);
    return res.data;
  }

  async performAPIRequest<T = any>({
    requestPromise,
    context,
  }: {
    requestPromise: Promise<any>;
    context: string;
    isArr?: boolean;
  }): Promise<T> {
    try {
      const response = await requestPromise;
      return response;
    } catch (e) {
      const error = e as Error;
      console.error(`Error during ${context}:`, { error });
      throw error;
    }
  }
}
