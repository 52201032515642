import { igApi } from '../../api';
import { DIMENSIONS } from '../../constants';
import { EditorSDKUtils } from './editorSdkUtils';

export const getAddedMultiPanelHeight = async (editorSDK): Promise<number> => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const instance = await editorSDKUtils.getInstance();
  const { instagramAccounts } = await igApi(instance).getAllAccounts();
  let addedMultiHeight = 0;
  // Start adding height only on the third account
  if (instagramAccounts && instagramAccounts?.length > 2) {
    const addedRadioBtns = instagramAccounts?.length - 2;
    addedMultiHeight = addedRadioBtns * DIMENSIONS.accountHeightOption;
    addedMultiHeight =
      addedMultiHeight > DIMENSIONS.maxPanelHeight
        ? DIMENSIONS.maxPanelHeight
        : addedMultiHeight;
  }
  return addedMultiHeight;
};
