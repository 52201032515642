import { HttpClient } from '@wix/yoshi-flow-editor';
import { listInstagramAccountMedia } from '@wix/ambassador-instagram-feed-v1-instagram-media/http';
import {
  disconnectAccount,
  getInstagramAccount,
  initiateAccountConnection,
  listInstagramAccounts,
} from '@wix/ambassador-instagram-feed-v1-instagram-account/http';
import {
  CursorQuery,
  ListInstagramAccountMediaResponse,
} from '@wix/ambassador-instagram-feed-v1-instagram-media/types';
import {
  DisconnectAccountResponse,
  GetInstagramAccountResponse,
  InitiateAccountConnectionResponse,
  ListInstagramAccountsResponse,
} from '@wix/ambassador-instagram-feed-v1-instagram-account/types';
import { ApiClient } from './apiClient';
import { getNextCursorAndHasNext } from '../utils/Viewer';
import { GetMediasOptions, checkIfMediaBlocked, setPagination } from './apiHelpers';

export const igApi = (instance: string) => {
  const httpClient = new HttpClient({
    headers: {
      Authorization: instance,
    },
  });

  const apiClient = new ApiClient(httpClient);

  const getMedias = async (
    connectionId: string,
    {
      limit = null,
      isViewMode = true,
      paginationInstance = null,
    }: GetMediasOptions = {}
  ) => {
    try {
      if (isViewMode && paginationInstance) {
        const isBlocked = checkIfMediaBlocked(paginationInstance);
        if (isBlocked) {
          console.log(`[getMedias]: ${isBlocked}`);
          return { media: [] } as ListInstagramAccountMediaResponse;
        }

        const paging = { cursorPaging: setPagination(paginationInstance, limit) };
        paginationInstance.setIsLoading(true);
        const data = await mediaReq(paging);
        paginationInstance.setCursor(getNextCursorAndHasNext(data));
        return data;
      } else {
        const paging: CursorQuery = limit ? { cursorPaging: { limit } } : {};
        return mediaReq(paging);
      }
    } catch (err) {
      console.error('[getMedias] error', err);
      throw err;
    }

    async function mediaReq(paging: CursorQuery) {
      return apiClient.performAPIRequest<ListInstagramAccountMediaResponse>({
        context: 'getIgMedias',
        requestPromise: apiClient.request<ListInstagramAccountMediaResponse>(
          listInstagramAccountMedia({ connectionId, paging })
        ),
      });
    }
  };

  const getAllAccounts = async () => {
    return apiClient.performAPIRequest<ListInstagramAccountsResponse>({
      context: 'listIgAccounts',
      requestPromise: apiClient.request<ListInstagramAccountsResponse>(
        listInstagramAccounts({})
      ),
    });
  };

  const getAccountHash = async () => {
    return apiClient.performAPIRequest<InitiateAccountConnectionResponse>({
      context: 'initiateAccount',
      requestPromise: apiClient.request<InitiateAccountConnectionResponse>(
        initiateAccountConnection({})
      ),
    });
  };

  const disconnect = async (connectionId: string) => {
    console.log('disconnectAccountApi:', { connectionId });
    return apiClient.performAPIRequest<DisconnectAccountResponse>({
      context: 'disconnectUser',
      requestPromise: apiClient.request<DisconnectAccountResponse>(
        disconnectAccount({ connectionId })
      ),
    });
  };

  const getAccount = async (connectionId: string) => {
    return apiClient.performAPIRequest<GetInstagramAccountResponse>({
      context: 'getIgUser',
      requestPromise: apiClient.request<GetInstagramAccountResponse>(
        getInstagramAccount({ connectionId })
      ),
    });
  };

  return {
    disconnect,
    getAllAccounts,
    getAccount,
    getMedias,
    getAccountHash,
  };
};
