import { igApi } from '../../api';
import { EXPAND_MODE_ID } from '../../constants';
import { deleteLightbox } from './lightbox';

export const onWidgetDeletion = async (editorSDKUtils) => {
  const isLast = await isLastWidget(editorSDKUtils);
  if (isLast) {
    await disconnectAllAccounts(editorSDKUtils);
    try {
      await deleteLightbox(editorSDKUtils);
    } catch (err) {}
    await editorSDKUtils.uninstallApplication();
  }
};

const isLastWidget = async (editorSDKUtils): Promise<boolean> => {
  let isLastAndExpand = false;
  const allRefs = await editorSDKUtils.getAllAppRefComponents();
  const noWidgetsLeft = !allRefs?.length;
  if (allRefs?.length === 1) {
    const [widgetRef] = await editorSDKUtils.getCompStructure(allRefs[0]);
    isLastAndExpand = widgetRef?.data?.widgetId === EXPAND_MODE_ID;
  }
  return isLastAndExpand || noWidgetsLeft;
};

const disconnectAllAccounts = async (editorSDKUtils) => {
  const instance = await editorSDKUtils.getInstance();
  const { instagramAccounts } = await igApi(instance).getAllAccounts();
  if (instagramAccounts?.length) {
    instagramAccounts.forEach((account) => {
      igApi(instance).disconnect(account.id as string);
    });
  }
};
