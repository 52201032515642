export enum PresetNames {
  Grid = 'Grid 3X3',
  OneRow = 'Slide 1 row',
  TwoRows = 'Slide 2 row',
  SideBySide = 'Side by side',
  SideBySide_RTL = 'Side by side RTL',
  Grid2x3 = 'Grid 2X3 load more',
  Grid_M = 'Mobile Grid 2X2',
  OneRow_M = 'Mobile slide 1 row',
  TwoRows_M = 'Mobile slide 1 row',
  SideBySide_M = 'Mobile side by side (slide)',
  Grid2x3_M = 'Mobile Grid 2X2 load more',
}

export enum PresetIds {
  Grid = 'variants-lgnn37aw',
  OneRow = 'variants-lp9mfx9p',
  TwoRows = 'variants-lub6nnkd',
  SideBySide = 'variants-lpl1r3cn',
  SideBySide_RTL = 'variants-lq0qjg52',
  Grid2x3 = 'variants-lz6ykm17',
  Grid_M = 'variants-lppfiaap',
  OneRow_M = 'variants-lppe23tr',
  TwoRows_M = 'variants-lppfaulg',
  SideBySide_M = 'variants-lppfwk96',
  Grid2x3_M = 'variants-lz84nu1z',
}

export enum HeaderPresetIds {
  OneRow = 'variants-lowzfwlv',
  TwoRowsC = 'variants-ln8wmavk1',
  TwoRowsL = 'variants-lpi8l3pv',
  TwoRowsR = 'variants-lpi8nzq5',
  TwoRowsC_M = 'variants-ls2zlz07',
  TwoRowsL_M = 'variants-ls30nq7b',
  TwoRowsR_M = 'variants-ls30rltv',
}

export enum HeaderPresetNames {
  OneRow = 'One row',
  TwoRowsC = 'Two rows C',
  TwoRowsL = 'Two rows L',
  TwoRowsR = 'Two rows R',
  TwoRowsC_M = 'Mobile Two rows C',
  TwoRowsL_M = 'Mobile Two rows L',
  TwoRowsR_M = 'Mobile Two rows R',
}

export enum ExpandPresets {
  Desktop = 'variants-lp17fr9x',
  Mobile = 'variants-lp86ddue',
  Studio = 'variants-lqp35htn',
}

export enum PresetHeights {
  OneRow = 400,
  TwoRows = 720,
  SideBySide = 500,
  Grid2x3 = 700,
  TwoRows_M = 420,
  SideBySide_M = 410,
  Grid2x2_m = 460,
}
