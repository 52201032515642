import {
  IPanelsApi,
  PanelsApiFactory,
} from '@wix/blocks-widget-services/panels';
import {
  EditorScriptFlowAPI,
  FlowAPI,
  FlowEditorSDK,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import { ComponentRef, EditorReadyOptions } from '@wix/platform-editor-sdk';
import { ElementRoles, PanelNames } from '../../enums';
import { openElementsPanel } from '../../panels/elementsPanel/elementsPanelActions';
import { EditorSDKUtils } from './editorSdkUtils';
import {
  EXPERIMENTS,
  FULL_WIDGET_CONTROLLER_ID,
  DIMENSIONS,
  HELP_ID,
} from '../../constants';
import { getAddedMultiPanelHeight } from './getAddedMultiPanelHeight';
import { getPanelUrl } from '@wix/yoshi-flow-editor/build/cjs/exports/utils';

export const createGfppActions = ({
  editorSDK,
  componentRef,
  options,
  flowAPI,
}: {
  editorSDK: FlowEditorSDK;
  componentRef: ComponentRef;
  options?: EditorReadyOptions;
  flowAPI: EditorScriptFlowAPI;
}) => {
  const editorSDKUtils = new EditorSDKUtils(editorSDK);
  const openButtonPanel = ({
    link = true,
    label = true,
    icon = true,
  }: {
    link?: boolean;
    label?: boolean;
    icon?: boolean;
  }) => {
    editorSDKUtils.openNativeComponentPanel({
      componentRef,
      controls: {
        link: { hidden: link },
        label: { hidden: label },
        icon: { hidden: icon },
      },
    });
  };
  const openAccountSettings = async (getAncestors: boolean = false) => {
    const { t } = flowAPI.translations;
    let ref = componentRef;
    if (getAncestors) {
      const widgetHost = await editorSDKUtils.getHost(componentRef);
      const children = await editorSDKUtils.getChildren(widgetHost);
      ref = children[0];
    }
    const props = await editorSDKUtils.getWidgetProps(ref);
    const metasiteId = await editorSDKUtils.getMetaSiteId();
    const addedMultiHeight = await getAddedMultiPanelHeight(editorSDK);

    // TODO - Create Experiment and check
    const isTemplates = flowAPI.experiments.enabled(
      EXPERIMENTS.TEMPLATES_SETTINGS,
    );

    const connectPanel = {
      componentRef,
      title: t('instagram-blocks.panel.accountSettings.title'),
      url: getPanelUrl('Gallery and Header', 'AccountSettings'),
      initialData: { props, metasiteId, componentRef: ref },
      width: DIMENSIONS.panelWidth,
      height: DIMENSIONS.connectPanelHeight + addedMultiHeight,
      helpId: HELP_ID,
    };

    const templateSettingsPanel = {
      componentRef,
      title: t('instagram-blocks.panel.templates-settings.title.notInUse'),
      url: getPanelUrl('Gallery and Header', 'TemplatesSettings'),
      initialData: { props, metasiteId, componentRef },
      width: DIMENSIONS.panelWidth,
      height: DIMENSIONS.templatesPanelHeight,
      helpId: HELP_ID,
    };

    const panel = isTemplates ? templateSettingsPanel : connectPanel;

    return editorSDKUtils.openComponentPanel(panel);
  };

  const openLayout = async () => {
    const { t } = flowAPI.translations;
    const panelsApi = (await getPanelsApi()) as IPanelsApi;
    panelsApi.openBlocksPanel(PanelNames.Layout, componentRef, {
      overrides: { title: t('instagram-blocks.panel.layout.label') },
    });
  };

  const openElements = async ({ t }) => {
    const compData = await editorSDKUtils.getComponentData(componentRef);
    const isExpand = FULL_WIDGET_CONTROLLER_ID !== compData.controllerType;
    return openElementsPanel(editorSDK, componentRef, t, isExpand);
  };

  const openPgCustomSetting = async () => {
    const { t } = flowAPI.translations;
    const panelsApi = (await getPanelsApi()) as IPanelsApi;
    panelsApi.openBlocksPanel(PanelNames.PgCustomSettings, componentRef, {
      overrides: {
        title: t('instagram-blocks.panel.mainPg.customSettings.title'),
      },
    });
  };

  const openExpandSettings = async () => {
    const { t } = flowAPI.translations;
    const panelsApi = (await getPanelsApi()) as IPanelsApi;
    panelsApi.openBlocksPanel(PanelNames.ExpandSettings, componentRef, {
      overrides: {
        title: t('instagram-blocks.expand.panel.customSettings.title'),
      },
    });
  };

  const getPanelsApi = async () => {
    if (!options) {
      console.log('ig blocks getPanelsApi no options');
      return;
    }
    const panelsApi = await new PanelsApiFactory().createPanelsApi({
      editorSDK,
      editorType: options.origin.type,
      essentials: flowAPI.essentials,
    });

    return panelsApi;
  };

  return {
    openAccountSettings,
    openElements,
    openLayout,
    removeLinksFromButtonPanel,
    openPgCustomSetting,
    openExpandSettings,
    openButtonPanel,
  };
};

export const removeLinksFromButtonPanel = (
  widgetBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  t: FlowAPI['translations']['t'],
  buttons: ElementRoles[],
  isLabelHidden: boolean = false,
  isEnabled: boolean = true,
) => {
  buttons.forEach((button) => {
    widgetBuilder.configureConnectedComponents(
      button,
      (connectedComponentsBuilder) => {
        connectedComponentsBuilder.behavior().set({
          essential: { enabled: isEnabled },
          closed: { selectable: true },
        });
        connectedComponentsBuilder.gfpp().set('mainAction1', {
          label: t('instagram-blocks.expand.stylabeButton.gfpp.mainAction'),

          onClick: (event: any) => {
            const { componentRef } = event.detail;
            const editorSdkUtils = new EditorSDKUtils(editorSDK);
            editorSdkUtils.openStylableButtonMenu(componentRef, isLabelHidden);
          },
        });
      },
    );
  });
};

export const removeGfppFromTextElements = (
  widgetBuilder: WidgetBuilder,
  elements: ElementRoles[],
) => {
  elements.forEach((element) => {
    widgetBuilder.configureConnectedComponents(
      element,
      (connectedComponentsBuilder) => {
        connectedComponentsBuilder
          .gfpp()
          .set('mainAction1', { behavior: 'HIDE' })
          .set('link', { behavior: 'HIDE' });

        connectedComponentsBuilder
          .behavior()
          .set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
      },
    );
  });
};
