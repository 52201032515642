import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { ElementRoles } from '../../enums';
import {
  removeGfppFromTextElements,
  removeLinksFromButtonPanel,
} from '../../utils/Editor/gfppUtils';
import { GalleryCoverConfig, NICKNAME } from '../../constants';

const expandButtonElements = [
  ElementRoles.LeftArrowBtn,
  ElementRoles.RightArrowBtn,
  ElementRoles.ExpandCloseBtn,
  ElementRoles.ExpandIGBtn,
];

const expandTextElements = [
  ElementRoles.ExpandDescription,
  ElementRoles.ExpandDate,
  ElementRoles.ExpandUserName,
];

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI
) => {
  const { t } = flowAPI.translations;
  widgetBuilder.set({
    displayName: t('instagram-blocks.expand.displayName'),
    nickname: NICKNAME.expandMode,
  });

  widgetBuilder.behavior().set({
    rotatable: false,
    canReparent: false,
    duplicatable: false,
    preventHide: true,
    removable: false,
    resizable: false,
    toggleShowOnAllPagesEnabled: false,
  });

  widgetBuilder.configureConnectedComponents(
    ElementRoles.ExpandProGallery,
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .gfpp()
        // .set('mainAction1', {
        //   label: t('instagram-blocks.expand.gfpp.mainAction1'),
        //   actionId: 'settingsPGExpand',
        // })
        .set('mainAction1', {
          label: t('instagram-blocks.expand.gfpp.changeDesign'),
          actionId: '',
          actionType: 'design',
        })
        .set('settings', { behavior: 'HIDE' })
        .set('layout', { behavior: 'HIDE' });

      connectedComponentsBuilder
        // @ts-expect-error
        .panel('design')
        // @ts-expect-error
        .configureControls(GalleryCoverConfig);
    }
  );

  widgetBuilder.configureConnectedComponents(
    ElementRoles.ExpandProGallery,
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .gfpp('mobile')
        .set('mainAction1', {
          label: t('instagram-blocks.expand.gfpp.changeDesign'),
          actionId: '',
          actionType: 'design',
        })
        .set('settings', { behavior: 'HIDE' })
        .set('layout', { behavior: 'HIDE' });
    }
  );

  removeGfppFromTextElements(widgetBuilder, expandTextElements);
  removeLinksFromButtonPanel(widgetBuilder, editorSDK, t, expandButtonElements);
};
