import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { PgCustomOnclick, PresetNames } from '../../enums';
import { LoadingPropsI, LoadingPropsI_m, WidgetPropsI } from '../../types';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/

export interface PropModelType {
  type: any;
  defaultValue: any;
  description: string;
  loadingValue?: any;
}

export type Loading_Props_m_Type = Record<keyof LoadingPropsI_m, PropModelType>;
export type Loading_Props_Type = Record<keyof LoadingPropsI, PropModelType>;

export const loadingProps: Loading_Props_Type = {
  showLoadMore: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: false,
    loadingValue: true,
    description: 'Is load more button is shown',
  },
  mediasOnInitialLoad: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 30,
    loadingValue: 6,
    description: 'Initial posts to show',
  },
  mediasOnLoadMore: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 6,
    loadingValue: 6,
    description: 'Additional posts to fetch',
  },
  shouldLimitImages: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: false,
    loadingValue: true,
    description: 'Is load more button is shown',
  },
};

export const loadingProps_m: Loading_Props_m_Type = {
  showLoadMore_m: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
    loadingValue: true,
    description: 'Is load more button is shown mobile',
  },
  mediasOnInitialLoad_m: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 4,
    loadingValue: 4,
    description: 'Initial posts to show mobile',
  },
  mediasOnLoadMore_m: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 4,
    loadingValue: 4,
    description: 'Additional posts to fetch mobile',
  },
  shouldLimitImages_m: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: false,
    loadingValue: true,
    description: 'Is load more button is shown mobile',
  },
};

export const modelProps: Record<keyof WidgetPropsI, PropModelType> = {
  // Here you can define your widget props
  onClickAction: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: PgCustomOnclick.OpenExpand,
    description: 'Opens the lightbox with the expand mode widget',
  },
  accountId: {
    type: WidgetPropertyType.STRING,
    defaultValue: '',
    description: 'Contains the id of the ig account',
  },
  preset: {
    type: WidgetPropertyType.STRING,
    defaultValue: PresetNames.OneRow,
    description: 'Contains the current preset',
  },
  mobilePreset: {
    type: WidgetPropertyType.STRING,
    defaultValue: '',
    description: 'Contains the current mobile preset',
  },
  isClassic: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
    description: 'Contains the widget environment',
  },
  templatesData: {
    type: WidgetPropertyType.STRING,
    defaultValue: '',
    description: 'Stringified object with folderName, userName and mediaCount',
  },
  ...loadingProps,
  ...loadingProps_m,
};

export default createBlocksModel({
  widgetName: 'Gallery and Header',
  props: modelProps,
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
