import { buttonClick, statusChanged } from '@wix/bi-logger-wixlabs-users/v2';
import {
  instagramFeedAppLoadsStartLoading,
  instagramFeedAppLoads,
  clickOnButtonOrLink,
  instagramViewPost
} from '@wix/bi-logger-wixlabs-ugc/v2';

interface LogParams {
  [key: string]: any;
}

interface ReportProps {
  evid: number;
  src: number;
  endpoint: string;
  params: LogParams;
}

export type BiFunction = (...args: any[]) => ReportProps;

const actionType = [
  'buttonClick',
  'appLoadedStart',
  'appLoadedEnd',
  'statusChanged',
  'clickOnButtonOrLink',
  'instagramViewPost'
] as const;

const entity = [null, 'preset'] as const;

export type BiActionType = (typeof biCommon.actionType)[number];

export const biCommon = {
  actionType,
  entity,
};

const BI_FUNCS: { [key in BiActionType]: BiFunction } = {
  buttonClick,
  clickOnButtonOrLink,
  statusChanged,
  instagramViewPost,
  appLoadedStart: instagramFeedAppLoadsStartLoading,
  appLoadedEnd: instagramFeedAppLoads,
};

export const getBiEventFunction = (
  biType: BiActionType = 'buttonClick'
): BiFunction => BI_FUNCS[biType];
