import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { PgCustomOnclick } from '../../enums';

export default createBlocksModel({
  widgetName: 'Expand Mode',
  props: {
    onClickAction: {
      type: WidgetPropertyType.NUMBER,
      defaultValue: PgCustomOnclick.NothingHappens,
      description: 'PG action on click',
    },
  },
  methods: {},
  events: {
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
